import styled from 'styled-components'

import { blueDarkMia, blueLightMia, blueMia, whiteMia } from '../../styles/colorStyles'

export const HomeContainer = styled.section `
  background: ${blueDarkMia};
  background: linear-gradient(90deg, ${blueDarkMia} 0%, ${blueMia} 100%);
  width: 100vw;

  @media (min-width:600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    background: linear-gradient(180deg, ${blueDarkMia} 0%, ${blueMia} 100%);
    width: 100vw;
  }

  @media (min-width:1600px) and (max-width: 2560px) {

  }
`

export const MainContainer = styled.main `
  display: flex;
 
  
  > div:nth-child(1) {
    width: 50%;

    > h2 {
      color: ${blueMia};
      font-size: 3.5vw;
      font-family: 'InterBold';
      width: 50%;
      margin-left: 25%;
      margin-top: 15%;
    }

    > h1 {
      color: ${whiteMia};
      font-size: 1.2vw;
      font-family: 'InterRegular';
      margin-left: 25%;
      width: 60%;


      >span {
        color: ${blueMia};
        font-size: 1.5vw;
      }
    }
  }

  > figure:nth-child(2) {
    width: 50%;

    > img {
      width: 60%;
      height: auto;
      margin-top: -15%;
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {}

  @media (max-width: 600px) {
    flex-direction: column;

    > div:nth-child(1) {
      width: 100%;

      > h2 {
        font-size: 13vw;
        width: 100%;
        margin-left: 10%;
        margin-top: 15%;
      }

      > h1 {
        font-size: 5vw;
        margin-top: 5%;
        margin-left: 10%;
        width: 80%;


        >span {
          font-size: 6vw;
        }
      }
    }

    > figure:nth-child(2) {
      width: 100%;

    > img {
      width: 100%;
      height: auto;
      margin-top: 2%;
    }
  }
}   

@media (min-width:1600px) and (max-width: 2560px) {

}

`

export const Choose = styled.section `
  display: flex;
  gap: 0.5%;
  border-radius: 25px;
  width: 70%;
  margin: -5.5% auto 8% auto;
  box-shadow: 0px 10px 20px #00000029;
  background-color: ${whiteMia};

  > div {
    width: 50%;
    background-color: ${blueLightMia};
    padding-top: 10%;
    padding-bottom: 10%;


    > h2 {
      font-family: 'InterBold';
      font-size: 2.5vw;
      color: ${blueMia};
      text-align: center;
      margin-bottom: 7%;
    }

    > a {
      display: block;
      color: ${whiteMia};
      font-family: 'InterRegular';
      text-decoration: none;
      text-align: center;
      font-size: 1vw;
      width: 30%;
      margin: auto;
      padding: 2%;
      border-radius: 20px;
      background-color: ${blueMia};
      transition: all .5s;
    }

    > a:hover {
      background-color: ${whiteMia};
      color: ${blueMia};
      border: 1px solid ${blueMia};
    }
  }

  > div:nth-child(1) {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;

    > figure {
      
      margin: auto auto 5% auto;
      width: 89px;
      height: 122px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  > div:nth-child(2) {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;

    > figure {
      margin: 2.5% auto 5% auto;
      width: 79px;
      height: 112px;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {}

  @media (max-width: 600px) {
    flex-direction: column;
    width: 80%;
    margin: -25% auto 15% auto;

    > div {
      width: 100%;
      padding-top: 15%;
      padding-bottom: 20%;


      > figure {
        width: 80px;
        height: auto;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > h2 {
        font-size: 7.5vw;
        margin-bottom: 7%;
      }

      > a {
        font-size: 5vw;
        width: 80%;
        padding: 6%;
        border-radius: 50px;
      }
    }

    > div:nth-child(1) {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      border-bottom-left-radius: 0;
      margin-bottom: 3%;

        > figure {
          margin: auto auto 5% auto;
          width: 69px;
          height: 94px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    > div:nth-child(2) {
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      border-top-right-radius: 0;

      > figure {
        margin: 1% auto 5% auto;
        width: 59px;
        height: 82px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (min-width:1600px) and (max-width: 2560px) {
    

    > div {
    


      > figure {
        width: 100px;
        height: 120px;

        > img {
         
        }
      }

      > h2 {
        
      }

      > a {
        
      }
    }

    > div:nth-child(1) {
      
    }

    > div:nth-child(2) {
     
    }
  }
`
