import React from 'react';

import { 
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaLinkedin
} from "react-icons/fa";

import {
  FooterCont,
  FooterInfo
} from './footer.styles'


export default function Footer () {

  return (
    <FooterCont>
      <a href="/">
        <figure>
          <img src="https://s3.amazonaws.com/mia-salud-1.0/mia_logotipo_blanco.svg" alt="" />
        </figure>
      </a>
      <FooterInfo>
        <div>
          <h5>Acerca de nosotros:</h5>
          <p>
            En MIA, creemos que un cuidado integral de la salud es necesario para que cualquier 
            ser humano esté pleno, sano y feliz. En esta plataforma encontrarás médicos con 
            experiencia que te darán el diagnóstico y tratamiento que necesitas. Siempre con un 
            cuidado humano y con la calidez que todas las personas merecen sin importar raza, 
            sexo, creencias, ni condición social. 
            En MIA, encuentras la mejor opción para cuidar de ti.
          </p>
        </div>
        <div>
          <h5>Nuestras consultas:</h5>
          <p>Medicina general</p>
          <p>Medicina integral</p>
          <p>Medicina alternativa</p>
          <p>Nutrición</p>
          <p>Odontología</p>
          <p>Cardiología</p>
          <p>Neurología</p>
        </div>
        <div>
          <h5>Para doctores:</h5>
          <a href="/soy-doctor">Trabaja con nosotros</a>
          <h5>Para pacientes:</h5>
          <a href="/soy-paciente">Afíliate con nosotros</a>
        </div>
        <div>
          <h5>Contacto:</h5>
          <p>
            Moctezuma #302. Int. 4 Col. Del Valle Centro. C.P. 03568. Ciudad de México.
          </p>
          <p> M. contacto@mia.com.mx W. (55) 1234.56789</p>
        </div>
        <div>
          <h5>Redes sociales</h5>
          <div>
            <a href="https://www.facebook.com/LaSaludEsMIA/ "><FaFacebook size="1.5vw" color="#FFFFFF"/></a>
            <a href="https://www.instagram.com/lasaludesmia/"><FaInstagram size="1.5vw" color='#FFFFFF'/></a>
            <a href="https://wa.link/7c59ut"><FaWhatsapp size="1.5vw" color='#FFFFFF'/></a>
            <a href="https://www.linkedin.com/in/la-salud-es-mia-2b3307236"><FaLinkedin size="1.5vw" color='#FFFFFF'/></a>
          </div>
          <div>
            <a href="https://www.facebook.com/LaSaludEsMIA/ "><FaFacebook size="7vw" color="#FFFFFF"/></a>
            <a href="https://www.instagram.com/lasaludesmia/"><FaInstagram size="7vw" color='#FFFFFF'/></a>
            <a href="https://wa.link/7c59ut"><FaWhatsapp size="7vw" color='#FFFFFF'/></a>
            <a href="https://www.linkedin.com/in/la-salud-es-mia-2b3307236"><FaLinkedin size="7vw" color='#FFFFFF'/></a>
          </div>
        </div>
      </FooterInfo>
      <div>
        <p>
          FAQ. | Política de privacidad. | Derechos Reservados MIA © 2021
        </p>
      </div>
    </FooterCont>
  )

}