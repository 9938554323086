import React from 'react'

import { HeaderContainer } from './logoMia.styles'

export default function LogoMIA () {

  return (
    <HeaderContainer>
      <figure>
        <img src="https://mia-provisional-new.s3.amazonaws.com/mialogo.svg" alt="logo mia" />
      </figure>
    </HeaderContainer>
  )
}