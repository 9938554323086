import styled from "styled-components"

import { blueDarkMia, blueLightMia, blueMia, whiteMia } from '../../../styles/colorStyles'

import { fadeIn, fadeOut } from "../../../styles/animations"

export const ModalLackOfData = styled.section `
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${props => props.showModalLackOfData ? 'visible': 'hidden' };
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0,0,0,0.5);
  z-index: 1000;
  animation: ${props => props.showModalLackOfData ? fadeIn : fadeOut} 0.5s linear;
  transition: all 0.5s;
 

  > div {
    width: 25%;
    height: 500px;
    background-color: ${blueLightMia};
    border-radius: 20px;
    padding-top: 8%;
    padding-bottom: 5%;

    > figure {
      width: 80px;
      height: 80px;
      margin: auto auto 8% auto;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > h3 {
      margin: auto;
      width: 80%;
      font-family: 'InterBold';
      font-size: 1.3vw;
      color: ${blueDarkMia};
      text-align: center;
      margin-bottom: 10%;

    }

    > button {
      display: block;
      color: ${whiteMia};
      font-family: 'InterRegular';
      text-decoration: none;
      text-align: center;
      font-size: 1vw;
      width: 50%;
      margin: auto;
      padding: 2.5%;
      border: 1px solid ${blueMia};
      border-radius: 50px;
      background-color: ${blueMia};
      cursor: pointer;
      transition: all .5s;
    }

    > button:hover {
      background-color: ${whiteMia};
      color: ${blueMia};
      border: 1px solid ${blueMia};
    }
  }

  @media (min-width: 600px) and (max-width:1024px) {
  

    > div {
      height: 400px;

      > figure {
        width: 70px;
        height: 70px;

        > img {
          
        }
      }

      > h3 {
        
      }

      > button {
        
      }
    }
  }

  @media (max-width: 600px) {
   

    > div {
      margin-right: 9%;
      width: 65%;
      padding-top: 20%;
      padding-bottom: 5%;
      height: 450px;

      > figure {
   

        > img {
          
        }
      }

      > h3 {
        margin: auto;
        width: 63%;
        font-size: 5vw;
        margin-bottom: 15%;

      }

      > button {
        display: block;
        color: ${whiteMia};
        font-family: 'InterRegular';
        text-decoration: none;
        text-align: center;
        font-size: 4vw;
        width: 80%;
        margin: auto;
        padding: 3.5%;
        border: 1px solid ${blueMia};
        border-radius: 50px;
        background-color: ${blueMia};
        cursor: pointer;
      }
    }   
  }

  @media (min-width:1600px) and (max-width: 2560px) {
   

    > div {
      height: 900px;
      padding-top: 8%;
      padding-bottom: 5%;

      > figure {
        width: 150px;
        height: 150px;
        margin: auto auto 8% auto;

        > img {
          
        }
      }

      > h3 {
        width: 80%;
        font-size: 1.6vw;
        margin-bottom: 10%;
      }

      > button {
        font-size: 1.3vw;
        width: 50%;
      }
    }
  }
`