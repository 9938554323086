import styled from 'styled-components'

export const HeaderContainer = styled.header `
  padding-top: 4%;
  padding-left: 5%;

  > figure {
    width: 90px;

    > img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width:600px) {
    padding-top: 10%;
    padding-left: 10%;

    > figure {
      width: 80px;

      > img {
        width: 100%;
        height: auto;
      }
    }
  }

  @media (min-width:1600px) and (max-width: 2560px) {
    padding-top: 4%;
    padding-left: 5%;

    > figure {
      width: 150px;

      > img {
        width: 100%;
        height: auto;
      }
    }
  }

`