import React, { useState, useEffect } from 'react'

import { ModalEmailSent } from './emailSent.styles'

export default function EmailSent (props) {
  const [ emailSent, setEmailSent ] = useState(props.showModalEmailSent)

  useEffect( () => {
    setEmailSent(props.showModalEmailSent)
  },[props.showModalEmailSent])

  return (
    <ModalEmailSent showModalEmailSent={emailSent}>
      <div>
        <figure>
          <img src="https://mia-provisional-new.s3.amazonaws.com/iconoSuccess.png" alt="icono de advertencia" />
        </figure>
        <h3>Se ha enviado tu solicitud satisfactoriamente.</h3>
        <div></div>
        <p>En breve nos comunicaremos contigo.</p>
        <a href='/'>Continuar</a>
      </div>
    </ModalEmailSent>
  )
}