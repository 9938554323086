import React, { useState, useEffect } from 'react'

import { ModalEmailNotSent } from './emailNotSent.styles'

export default function EmailNotSent (props) {
  const [ emailNotSent, setEmailNotSent ] = useState(props.showModalEmailNotSent)

  useEffect( () => {
    setEmailNotSent(props.showModalEmailNotSent)
  },[props.showModalEmailNotSent])
  
  const closeModal = () => {
    setEmailNotSent(false)
    props.setShowModalEmailNotSent(false)
  }

  return (
    <ModalEmailNotSent showModalEmailNotSent={emailNotSent}>
      <div>
        <figure>
          <img src="https://mia-provisional-new.s3.amazonaws.com/iconoError.png" alt="icono de advertencia" />
        </figure>
        <h3>Lo sentimos mucho, no pudimos enviar el correo. Intente más tarde.</h3>
        <button onClick={closeModal}>Cerrar</button>
      </div>
    </ModalEmailNotSent>
  )
}