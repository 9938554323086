import { createGlobalStyle } from 'styled-components'


export const GlobalStyles = createGlobalStyle `

  * {
    font-size: 16px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: padding-box;
    -moz-box-sizing: padding-box;
  }

`