
export const blueMia = 'rgb(0,175,240)'

export const blueLightMia = 'rgb(237,247,252)'

export const blueDarkMia = 'rgb(45,50,150)'

export const whiteMia = 'rgb(255,255,255)'

export const greyText = 'rgb(110,112,130)'

export const greyLight = 'rgb(181,181,181)'

