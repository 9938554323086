import styled from "styled-components";

import {
  blueDarkMia,
  blueMia,
  whiteMia,
  greyText
} from '../../../styles/colorStyles'

export const FooterCont  = styled.section `
  display:flex;
  flex-direction:column;
  padding-bottom:3% ;
  background-color:${blueDarkMia};

  > a {  
      > figure {
      width: 4.5%;
      margin:3% 0 0 11%;
      > img {
        width: 100%;
        height:100%;
      }
    }
  }

  > div:nth-child(3) {
    margin:2% auto ;
    width: 78%;
    height: 1px;
    background-color: ${greyText};

    > p {
      margin-top:1%;
      text-align: center;
      color: ${whiteMia};
      font-family: 'InterMedium';
      font-size:.7vw ;
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {
   
    > a {
      
        > figure {
          width: 4.5%;
        > img {
         
        }
      }
    }
    > div:nth-child(3) {
      > p {
        font-size:1vw ;
      }
    }
  }

  @media (max-width: 600px) {
    padding-bottom:25% ;
    
    > a {
      > figure {
        width: 20%;
        margin:10% 0 0 11%;
        
        > img {
          width: 100%;
          height:100%;
        }
      }
    }

    > div:nth-child(3) {
      margin:2% auto ;
      width: 80%;
      height: 2px;
      background-color: ${greyText} ;
      
      > p {
        margin:8% auto;
        width: 45%;
        font-size: 3vw ;
      }
    }
  }
`

export const FooterInfo  = styled.section `
  display: flex; 
  justify-content: space-between; 
  width:78%;
  margin:1% auto;
 
  > div:nth-child(1) {
    display: flex;
    flex-direction:column;
    width: 29.3%;
    
    > h5 {
      color: ${blueMia};
      font-family: 'InterBold';
      font-size:.7vw;  
    }
    > p {
      margin-top:3%;
      color: ${whiteMia};
      font-family: 'InterRegular';
      font-size:.7vw ;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction:column;
    width :10% ;
   
    > h5 {
      margin-bottom:9%;
      color: ${blueMia};
      font-family: 'InterBold';
      font-size:.7vw; 
    }

    > p:nth-child(2),
      p:nth-child(3),
      p:nth-child(4),
      p:nth-child(5),
      p:nth-child(6),
      p:nth-child(7),
      p:nth-child(8) {
      color: ${whiteMia};
      font-family: 'InterRegular';
      font-size:.7vw ;
      text-decoration:none;
      cursor: pointer;

      &:hover {
        color: ${blueMia};
      }
    }
  }
  > div:nth-child(3) {
    display: flex;
    flex-direction:column;
    width:10%;
   
    > h5:nth-child(1) {
      color: ${blueMia};
      font-family: 'InterBold';
      font-size:.7vw; 
    }
    > a:nth-child(2) {
      margin-top:9%;
      color: ${whiteMia};
      font-family: 'InterRegular';
      font-size:.7vw ;
      text-decoration:none;
      &:hover {
        color: ${blueMia};
      }
    }
    > h5:nth-child(3) {
      margin-top:25%;
      color: ${blueMia};
      font-family: 'InterBold';
      font-size:.7vw; 
    }
    > a:nth-child(4) {
      margin-top:10%;
      color: ${whiteMia};
      font-family: 'InterRegular';
      font-size:.7vw ;
      text-decoration:none;
      &:hover {
        color: ${blueMia};
      }
    }
  }
  > div:nth-child(4) {
    display: flex;
    flex-direction:column;
    width: 12%;
    
    > h5 {
      color: ${blueMia};
      font-family: 'InterBold';
      font-size:.7vw; 
    }
    > p:nth-child(2) {
      margin-top:7%;
      color: ${whiteMia};
      font-family: 'InterRegular';
      font-size:.7vw ;
    }
    > p:nth-child(3) {
      margin-top:12%;
      color: ${whiteMia};
      font-family: 'InterRegular';
      font-size:.7vw ;
    }
  }
  
  > div:nth-child(5) {
    display: flex;
    flex-direction:column;
    width: 15%;
    
    > h5 {
      color: ${blueMia};
      font-family: 'InterBold';
      font-size:.7vw; 
    }
    > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      
      > a:nth-child(1),
        a:nth-child(2),
        a:nth-child(3),
        a:nth-child(4),
        a:nth-child(5) {
        margin-top:10%;
      }
    }
    > div:nth-child(3) {
      display: none;
      justify-content: space-between;
      
      > a:nth-child(1),
        a:nth-child(2),
        a:nth-child(3),
        a:nth-child(4),
        a:nth-child(5) {
        margin-top:10%;
      }
    }
  }
  @media (min-width:600px) and (max-width: 1024px) {
  
    > div:nth-child(1) {
      
      > h5 {
        font-size:1vw;  
      }
      > p {
        font-size:.8vw ;
      }
    }
    > div:nth-child(2) { 
      width :11% ;
    
      > h5 {
        font-size: 1vw; 
      }

      > p:nth-child(2),
        p:nth-child(3),
        p:nth-child(4),
        p:nth-child(5),
        p:nth-child(6),
        p:nth-child(7),
        p:nth-child(8) {
        font-size: .8vw ;
        &:hover {
        
        }
      }
    }
    > div:nth-child(3) {
     
      > h5:nth-child(1) {
        font-size: 1vw; 
      }
      > a:nth-child(2) {
        font-size: .8vw ;
        &:hover {
         
        }
      }
      > h5:nth-child(3) {
        font-size: 1vw; 
      }
      > a:nth-child(4) {
        font-size:.8vw ;
        &:hover {
        
        }
      }
    }
    > div:nth-child(4) {
      width: 13%;
      
      > h5 {
        font-size: 1vw; 
      }
      > p:nth-child(2) {
        font-size:.8vw ;
      }
      > p:nth-child(3) {
        font-size:.8vw ;
      }
    }
    
    > div:nth-child(5) {
     
      > h5 {
        font-size: 1vw; 
      }

      > div:nth-child(2) {
       
        > a:nth-child(1),
          a:nth-child(2),
          a:nth-child(3),
          a:nth-child(4),
          a:nth-child(5) {
         
        }
      }

      > div:nth-child(3) {
        
        > a:nth-child(1),
          a:nth-child(2),
          a:nth-child(3),
          a:nth-child(4),
          a:nth-child(5) {
          
        }
      }
    }
  }

  @media (max-width: 600px) {
    display: flex; 
    flex-direction:column;
    justify-content: space-between; 
    width:80%;
    margin:7% auto;
  
    > div:nth-child(1) {
      width: 95%;
      
      > h5 {
        font-size:4vw;  
      }

      > p {
        margin-top:3%;
        font-size:3vw ;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction:column;
      width :50% ;
    
      > h5 {
        margin:10% 0 5% 0;
        font-size:4vw; 
      }

      > p:nth-child(2),
        p:nth-child(3),
        p:nth-child(4),
        p:nth-child(5),
        p:nth-child(6),
        p:nth-child(7),
        p:nth-child(8) {
        font-size: 3vw ;

        &:hover {
          
        }
      }
    }
    > div:nth-child(3) {
      display: flex;
      flex-direction:column;
      width:40%;
    
      > h5:nth-child(1) {
        margin-top: 10%;
        font-size: 4vw; 
      }
      > a:nth-child(2) {
        margin-top:5%;
        font-size: 3vw ;
        &:hover {
        
        }
      }
      > h5:nth-child(3) {
        margin-top:10%;
        font-size: 4vw; 
      }
      > a:nth-child(4) {
        margin-top:5%;
        font-size: 3vw ;
        &:hover {
         
        }
      }
    }
    > div:nth-child(4) {
      display: flex;
      flex-direction:column;
      width: 48%;
      
      > h5 {
        margin-top:10%;
        font-size: 4vw; 
      }
      > p:nth-child(2) {
        margin-top:4%;
        font-size: 3vw ;
      }
      > p:nth-child(3) {
        margin-top:8%;
        color: ${whiteMia};
        font-family: 'InterRegular';
        font-size:3vw ;
      }
    }
    
    > div:nth-child(5) {
      display: flex;
      flex-direction:column;
      width: 75%;
      
      > h5 {
        margin-top:15%;
        font-size:4vw; 
      }
      > div:nth-child(2) {
        display: none;
        
        > a:nth-child(1),
          a:nth-child(2),
          a:nth-child(3),
          a:nth-child(4),
          a:nth-child(5) {
        
        }
      }
      > div:nth-child(3) {
        display: flex;
        justify-content: space-between;
        
        > a:nth-child(1),
          a:nth-child(2),
          a:nth-child(3),
          a:nth-child(4),
          a:nth-child(5) {
          margin-top:10%;
        }
      }
    }
  }
`


