import styled from 'styled-components'

import { blueMia, greyText } from '../../../styles/colorStyles'

export const QuoteContainer = styled.section `
  margin-bottom: 8%;

  > h2 {
    font-family: 'InterBold';
    font-size: 3vw;
    color: ${greyText};
    text-align: center;
    width: 50%;
    margin: auto;

    > span:nth-child(1),
    > span:nth-child(3) {
      font-size: 3vw;
      color: ${blueMia};
    }

    > span:nth-child(2) {
      font-size: 3vw;
      color: ${blueMia};
      text-decoration: underline;
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {}

  @media (max-width: 600px) {
    margin-bottom: 15%;

    > h2 {
      font-size: 10vw;
      width: 65%;

      > span:nth-child(1),
      > span:nth-child(3) {
        font-size: 10vw;
      }

      > span:nth-child(2) {
        font-size: 10vw;
      }
    }
  }


`