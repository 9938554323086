import styled from "styled-components"

import { blueMia, whiteMia } from '../../../styles/colorStyles'

import { fadeIn, fadeOut } from "../../../styles/animations"

export const ModalEmailNotSent = styled.section `
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${props => props.showModalEmailNotSent ? 'visible': 'hidden' };
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0,0,0,0.5);
  z-index: 1000;
  animation: ${props => props.showModalEmailNotSent ? fadeIn : fadeOut} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 60%;
    background-color: ${whiteMia};
    border-radius: 20px;
    padding-top: 2.5%;
    padding-bottom: 5%;

    > figure {
      width: 100px;
      height: 100px;
      margin: auto auto 2.5% auto;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > h3 {
      font-size: 2.5vw;
      color: ${blueMia};
      text-align: center;
      margin-bottom: 5%;
    }

    > button {
      display: block;
      color: ${whiteMia};
      font-family: 'InterRegular';
      text-decoration: none;
      text-align: center;
      font-size: 1.2vw;
      width: 30%;
      margin: auto;
      padding: 2%;
      border: 1px solid ${blueMia};
      border-radius: 50px;
      background-color: ${blueMia};
      cursor: pointer;
    }
  }

  @media (min-width: 600px) and (max-width:1024px) {}

  @media (max-width: 600px) {

    > div {
      width: 90%;

      > figure {
        width: 25px;
        height: 25px;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > h3 {
        font-size: 2.5vw;
        margin-bottom: 5%;
      }

      > button {
        font-size: 1.2vw;
        width: 80%;
        padding: 2%;
      }
    }
  }

  
`