import styled from 'styled-components'

import {blueDarkMia,blueLightMia,blueMia, greyLight, greyText, whiteMia} from '../../styles/colorStyles'

export const PatientContainer = styled.section `
  background: ${blueDarkMia};
  background: linear-gradient(90deg, ${blueDarkMia} 0%, ${blueMia} 100%);
  width: 100vw;

  @media (min-width:600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    background: linear-gradient(180deg, ${blueDarkMia} 0%, ${blueMia} 100%);
  }

  @media (min-width:1600px) and (max-width: 2560px) {}
`

export const MainContainer = styled.main `
  display: flex;
  
  > div:nth-child(1) {
    width: 50%;

    > h2 {
      color: ${blueMia};
      font-size: 3.5vw;
      font-family: 'InterBold';
      width: 50%;
      margin-left: 25%;
      margin-top: 15%;
    }

    > h1 {
      color: ${whiteMia};
      font-size: 1.2vw;
      font-family: 'InterRegular';
      margin-left: 25%;
      width: 55%;


      >span {
        color: ${blueMia};
        font-size: 1.2vw;
        font-family: 'InterRegular';
      }
    }
  }

  > figure:nth-child(2) {
    width: 50%;

    > img {
      width: 55%;
      height: auto;
      margin-top: -8%;
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {}

  @media (max-width: 600px) {
    flex-direction: column;

    > div:nth-child(1) {
      width: 100%;

      > h2 {
        font-size: 13vw;
        width: 100%;
        margin-left: 10%;
        margin-top: 15%;
      }

      > h1 {
        font-size: 5vw;
        margin-top: 5%;
        margin-left: 10%;
        width: 80%;


        >span {
          font-size: 6vw;
        }
      }
    }

    > figure:nth-child(2) {
      width: 100%;

      > img {
        width: 100%;
        height: auto;
        margin-top: 2%;
      }
    }
  }

  @media (min-width:1600px) and (max-width: 2560px) {}
`

export const Form = styled.section `
  display: flex;
  border-radius: 25px;
  width: 70%;
  margin: -5.5% auto 8% auto;
  box-shadow: 0px 10px 20px #00000029;
  background-color: ${whiteMia};

  > div {
    width: 100%;
    background-color: ${blueLightMia};
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 25px;


    > figure {
      margin: auto auto 5% auto;
      width: 90px;
      height: 90px;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > h2 {
      font-family: 'InterBold';
      font-size: 2.5vw;
      color: ${blueMia};
      text-align: center;
      margin-bottom: 7%;
    }

    > h3 {
      color: ${blueDarkMia};
      font-family: 'InterRegular';
      font-size: 1.2vw;
      text-align: center;
    }

    > a {
      display: block;
      color: ${whiteMia};
      font-family: 'InterRegular';
      text-decoration: none;
      text-align: center;
      font-size: 1vw;
      width: 20%;
      margin: 2.5% auto;
      padding: 1%;
      border-radius: 20px;
      background-color: ${blueMia};
      transition: all .5s;
    }

    > a:hover {
      background-color: ${whiteMia};
      color: ${blueMia};
      border: 1px solid ${blueMia};
    } 
  }

  @media (min-width:600px) and (max-width: 1024px) {}

  @media (max-width: 600px) {
    width: 80%;
    margin: -25% auto 15% auto;

    > div {
      padding-top: 15%;
      padding-bottom: 15%;

      > figure {
        width: 70px;
        height: 70px;

        > img {
        }
      }

      > h2 {
        font-size: 7vw;
        margin-bottom: 7%;
      }

      > h3 {
        font-size: 6.5vw;
      }

      > h3:nth-child(5){
        margin-bottom: 8%;
      }

      > a {
        font-size: 5vw;
        width: 80%;
        margin: 8% auto 18% auto;
        padding: 4%;
        border-radius: 50px;
      }
    }
  }

  @media (min-width:1600px) and (max-width: 2560px) {}
`

export const FormData = styled.div `
  width: 50%;
  margin: 2.5% auto;

  > div {
    > p {
      font-size: 1.2vw;
      color: ${greyText};
      font-family: 'InterRegular';
    }

    > input {
      font-family: 'InterRegular';
      font-size: 1vw;
      color: ${greyText};
      width: 100%;
      height: 35px;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
      padding-left: 2.5%;
      border: 1px solid ${greyLight};
      outline: none;
      padding-left: 2.5%;
    }

    > select {
      background-color: ${whiteMia};
      border: thin solid ${greyLight};
      display: inline-block;
      color: ${greyText};
      font-family: 'InterRegular';
      line-height: 26px;
      padding: .8% 67% .8% 2%;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    > #classic {
      color: ${greyText};
      background-image:
      linear-gradient(45deg, transparent 50%, ${greyText} 50%),
      linear-gradient(135deg, ${greyText} 50%, transparent 50%),
      linear-gradient(to right, ${whiteMia}, ${whiteMia});
      background-position:
      calc(100% - 20px) calc(16px + 0px),
      calc(100% - 15px) calc(16px + 0px),
      100% 0;
      background-size:
      5px 5px,
      5px 5px,
      40px 40px;
      background-repeat: no-repeat;
      border-color: ${greyLight};
      outline: 0;
    }

    > select #classic:focus {
      background-image:
      linear-gradient(45deg, ${greyText} 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, ${greyText} 50%),
      linear-gradient(to right, ${whiteMia}, ${whiteMia});
      background-position:
      calc(100% - 15px) 16px,
      calc(100% - 20px) 16px,
      100% 0;
      background-size:
      5px 5px,
      5px 5px,
      40px 40px;
      background-repeat: no-repeat;
      border-color: grey;
      outline: 0;
    }
  }

  > button:last-child {
    display: block;
    color: ${whiteMia};
    font-family: 'InterRegular';
    text-decoration: none;
    text-align: center;
    font-size: 1vw;
    width: 40%;
    margin: 10% auto 0 auto;
    padding: 2%;
    border: 1px solid ${blueMia};
    border-radius: 20px;
    background-color: ${blueMia};
    cursor: pointer;
    transition: all 1s;
  }

  > button:hover {
    background-color: ${whiteMia};
    color: ${blueMia};
    border: 1px solid ${blueMia};
  }

  @media (min-width:600px) and (max-width: 1024px) {
    

    > div {
      > p {
      
      }

      > input {
        
      }

      > select {
        padding: .8% 55% .8% 2%;
      }

      > #classic {
        
      }

      > select #classic:focus {
        
      }
    }

    > button:last-child {
     
    }

    > button:hover {
      
    }

  }

  @media (max-width: 600px) {
    width: 85%;

    > div {
      > p {
        font-size: 5vw;
      }

      > input {
        font-size: 4vw;
        height: 40px;
      }

      > select {
        font-size: 3vw;
        padding: 2% 55% 2% 2%;
      }

      > #classic {
        
      }

      > select #classic:focus {
        background-image:
        linear-gradient(45deg, ${greyText} 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, ${greyText} 50%),
        linear-gradient(to right, ${whiteMia}, ${whiteMia});
        background-position:
        calc(100% - 15px) 16px,
        calc(100% - 20px) 16px,
        100% 0;
        background-size:
        5px 5px,
        5px 5px,
        40px 40px;
        background-repeat: no-repeat;
        border-color: grey;
        outline: 0;
      }
     
    }

    > button:last-child {
      font-size: 5vw;
      padding: 4%;
      width: 100%;
      border-radius: 30px;
    }
  }

  @media (min-width:1600px) and (max-width: 2560px) {
    

    > div {
      > p {
        
      }

      > input {
        height: 80px;
      }

      > select {
        height: 80px;
      }
    }

    > button {
      
    }
  }
`