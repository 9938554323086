import React, { useState } from 'react'
import axios from 'axios'

import {
  PatientContainer,
  MainContainer,
  Form,
  FormData
} from './patient.styles'

import LogoMIA from '../layout/logoMia/logoMia'
import Quote from '../layout/quote/quote'
import Footer from '../layout/footer/footer'

import ModalLackOfData from '../modals/lackOfData/lackOfData'
import ModalEmailNotSent from '../modals/emailNotSent/emailNotSent'
import ModalEmailSent from '../modals/emailSent/emailSent'

export default function Patient () {
  const [ user, setUser ] = useState({})
  const [ showModalLackOfData, setShowModalLackOfData ] = useState(false)
  const [ showModalEmailNotSent, setShowModalEmailNotSent ] = useState(false)
  const [ showModalEmailSent, setShowModalEmailSent ] = useState(false)

  const handleData = e => {
    user[e.target.name] = e.target.value
  }

  const sendDataToEmail = () => {
    if(Object.keys(user).length === 5) {
      axios.post('https://dxokwn6uyh.execute-api.us-east-1.amazonaws.com/dev/send-email/patient',user)
        .then( () => setShowModalEmailSent(true) )
        .catch( () => setShowModalEmailNotSent(true) )
    } else {
      setShowModalLackOfData(true)
    }
  }

  return (
    <>
      <ModalEmailSent showModalEmailSent={showModalEmailSent} />
      <ModalLackOfData showModalLackOfData={showModalLackOfData} setShowModalLackOfData={setShowModalLackOfData}/>
      <ModalEmailNotSent showModalEmailNotSent={showModalEmailNotSent} setShowModalEmailNotSent={setShowModalEmailNotSent} />
      <PatientContainer>
      <LogoMIA />
      <MainContainer>
        <div>
          <h2>Te damos la bienvenida</h2>
          <h1>
              En <span>MIA</span>, creemos que un cuidado integral 
              de la salud es necesario para que cualquier ser humano esté 
              pleno, sano y feliz.
          </h1>
        </div>
        <figure>
          <img src="https://mia-provisional-new.s3.amazonaws.com/familia.png" alt="Familia en MIA" />
        </figure>
      </MainContainer>
      </PatientContainer>
      <Form> 
        <div>
          <figure>
            <img src="https://mia-provisional-new.s3.amazonaws.com/iconoDate.png" alt="icono cita" />
          </figure>
          <h2>Agenda una cita</h2>
          <h3>Vía WhatsApp</h3>
          <a href="https://wa.link/7c59ut">Da clic aquí</a>
          <h3>Vía correo electrónico</h3>
          <FormData>
            <div>
              <p>Nombre:</p>
              <input onChange={handleData} type="text"/>
            </div>
            <div>
              <p>Teléfono:</p>
              <input onChange={handleData} type="tel" name='tel' required/>
            </div>
            <div>
              <p>Correo electrónico:</p>
              <input onChange={handleData} type="email" name='email' required/>
            </div>
            <div>
              <p>Especialidad:</p>
              <select id='classic' onChange={handleData} name="speciality" required>
                <option value="seleccionar">Seleccionar...</option>
                <option value="general">Medicina general</option>
                <option value="integral">Medicina integral</option>
                <option value="alternativa">Medicina alternativa</option>
                <option value="nutricion">Nutrición</option>
                <option value="odontologia">Odontología</option>
                <option value="cardiologia">Cardiología</option>
                <option value="neurologia">Neurología</option>
                <option value="otra">Otra</option>
              </select>
            </div>
            <div>
              <p>Fecha:</p>
              <input onChange={handleData} type="date" name='date' required/>
            </div>
            <button onClick={sendDataToEmail}>Enviar</button>
          </FormData>
        </div>
      </Form>
      <Quote />
      <Footer />
    </>
  )
}