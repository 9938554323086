import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './components/home/home.jsx'
import Doctor from './components/doctor/doctor.jsx'
import Patiente from './components/patient/patient.jsx'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/soy-doctor' element={<Doctor/>} />
        <Route path='/soy-paciente' element={<Patiente/>} />
      </Routes>
    </BrowserRouter>
  
  );
}

export default App;
