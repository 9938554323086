import React, { useState, useEffect } from 'react'

import { ModalLackOfData } from './lackOfData.styles'

export default function LackOfData (props) {
  const [ lackOfData, setLackOfData ] = useState(props.showModalLackOfData)

  useEffect( () => {
    setLackOfData(props.showModalLackOfData)
  },[props.showModalLackOfData])
  
  const closeModal = () => {
    setLackOfData(false)
    props.setShowModalLackOfData(false)
  }


  return (
    <ModalLackOfData showModalLackOfData={lackOfData}>
      <div>
        <figure>
          <img src="https://mia-provisional-new.s3.amazonaws.com/iconoWarning.png" alt="icono de advertencia" />
        </figure>
        <h3>Favor de llenar todos los campos del formulario.</h3>
        <button onClick={closeModal}>Cerrar</button>
      </div>
    </ModalLackOfData>
  )
}