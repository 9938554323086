import React from 'react'

import {
  HomeContainer,
  MainContainer,
  Choose
} from './home.styles'

import LogoMIA from '../layout/logoMia/logoMia'
import Quote from '../layout/quote/quote'
import Footer from '../layout/footer/footer'

export default function Home () {

  return (
    <>
      <HomeContainer>
        <LogoMIA />
        <MainContainer>
          <div>
            <h2>Te damos la bienvenida</h2>
            <h1>
                En La Salud es <span>MIA</span>, sabemos que 
                en el cuidado integral de la salud es necesario un 
                ser humano pleno, sano y feliz, física y mentalmente.
            </h1>
          </div>
          <figure>
            <img src="https://mia-provisional-new.s3.amazonaws.com/doctora.png" alt="Doctora de MIA" />
          </figure>
        </MainContainer>
      </HomeContainer>
      <Choose> 
        <div>
          <figure>
            <img src="https://mia-provisional-new.s3.amazonaws.com/iconoDoctor.png" alt="icono doctor" />
          </figure>
          <h2>Soy médico</h2>
          <a href="/soy-doctor">Clic aquí</a>
        </div>
        <div>
          <figure>
            <img src="https://mia-provisional-new.s3.amazonaws.com/iconoPaciente.png" alt="icono doctor" />
          </figure>
          <h2>Soy paciente</h2>
          <a href="/soy-paciente">Clic aquí</a>
        </div>
      </Choose>
      <Quote />
      <Footer />
      
    </>
  )
}