import React from 'react'

import { QuoteContainer } from './quote.styles'

export default function Quote () {

  return (
    <QuoteContainer>
      <h2>
        En <span>MIA</span> encuentras la mejor opción 
        para <span>cuidar de ti</span><span>.</span>
      </h2>
    </QuoteContainer>
  )
}